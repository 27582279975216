import React from "react";
import "./Reasons.css";
import Image1 from "../../assets/image1.png";
import Image2 from "../../assets/image2.png";
import Image3 from "../../assets/image3.png";
import Image4 from "../../assets/image4.png";
import NB from "../../assets/nb.png";
import Adidas from "../../assets/adidas.png";
import Nike from "../../assets/nike.png";
import Tick from "../../assets/tick.png";

const Reasons = () => {
  return (
    <div className="Reasons" id="Reasons">
      <div className="Left-R">
        <img src={Image1} alt="" />
        <img src={Image2} alt="" />
        <img src={Image3} alt="" />
        <img src={Image4} alt="" />
      </div>
      <div className="Right-R">
        <span>some reasons</span>
        <div>
          <span className="stroke-text">Why</span>
          <span> choose us?</span>
        </div>
        <div className="Detailes-R">
          <div>
            <img src={Tick} alt=""></img>
            <span>over 140+ expert coachs</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>train smarter and faster than before</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>1 free program for new member</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>reliable partners</span>
          </div>
        </div>
        <span id="ourpartners">OUR PARTNERS</span>
        <div className="Partners">
          <img src={NB} alt="" />
          <img src={Adidas} alt="" />
          <img src={Nike} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reasons;
