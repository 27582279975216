import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="Header">
      {/*img*/}
      <img src={Logo} alt="" className="Logo" />
      {menuOpen === false && mobile === true ? (
        <div className="Barss" onClick={() => setMenuOpen(true)}>
          <img src={Bars} alt="" className="Bars" />
        </div>
      ) : (
        <ul className="Header-Menu">
          <li>
            <Link
              to="Header"
              activeClass="active"
              span={true}
              smooth={true}
              onClick={() => setMenuOpen(false)}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="Programs"
              span={true}
              smooth={true}
              onClick={() => setMenuOpen(false)}
            >
              Programs
            </Link>
          </li>
          <li>
            <Link
              to="Reasons"
              span={true}
              smooth={true}
              onClick={() => setMenuOpen(false)}
            >
              Why us
            </Link>
          </li>
          <li>
            <Link
              to="Plan"
              span={true}
              smooth={true}
              onClick={() => setMenuOpen(false)}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              to="Testimonials"
              span={true}
              smooth={true}
              onClick={() => setMenuOpen(false)}
            >
              Testimonials
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
