import React from "react";
import "./Footer.css";
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import Linkedin from "../../assets/linkedin.png";
import Logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="Footer-Countainer">
      <hr />
      <div className="Footer">
        <div className="Social_links">
          <img src={Github} alt="" />
          <img src={Instagram} alt="" />
          <img src={Linkedin} alt="" />
        </div>
        <div className="Logo-F">
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className="Blur Footer-Blur-L"></div>
      <div className="Blur Footer-Blur-R"></div>
    </div>
  );
};

export default Footer;
