import React, { useRef } from "react";
import "./Join.css";
import EmailJS from "@emailjs/browser";

const Join = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    EmailJS.sendForm(
      "service_f2puk3m", // this name service has recived from https://dashboard.emailjs.com/admin
      "template_46fqzq6", // this name service has recived from https://dashboard.emailjs.com/admin
      form.current,
      "xY5PPRnFjHW2c_5kQ" // this name service has recived from https://dashboard.emailjs.com/admin
    ).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  return (
    <div className="Join-US" id="Join-US">
      <hr />
      <div className="Left-J">
        <div>
          <span className="stroke-text">Ready to</span>
          <span>Level up</span>
        </div>
        <div>
          <span>your body</span>
          <span className="stroke-text">with us?</span>
        </div>
      </div>
      <div className="Right-J">
        <form ref={form} className="Email-Countainer" onSubmit={sendEmail}>
          <input
            type="email"
            name="USER-EMAIL"
            placeholder="Enter Your Email Address Here ..."
          />
          <button className="btn">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
