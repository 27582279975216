import React from "react";
import "./Plan.css";
import PlansDataImported from "../../data/plansData";
import WhiteTick from "../../assets/whiteTick.png";

const Plan = () => {
  return (
    <div className="Plan-Countainers">
      <div className="Blur Plan-Blur-L"></div>
      <div className="Blur Plan-Blur-R"></div>
      <div className="PH">
        <span className="stroke-text">Ready to Start</span>
        <span>Your Journey</span>
        <span className="stroke-text">now withus</span>
      </div>
      <div className="Plans">
        {PlansDataImported.map((plane, index) => (
          <div className="Plan" key={index}>
            {plane.icon}
            <span>{plane.name}</span>
            <span>$ {plane.price}</span>
            <div className="Features">
              {plane.features.map((feature, index) => (
                <div className="Feature" key={index}>
                  <img src={WhiteTick} alt="" />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
            <div>
              <span>See more benefits -></span>
            </div>
            <button className="btn">Join Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plan;
